import * as React from 'react'
import { Link } from 'gatsby'

const Footer = ({ pageContext }) => {
  const { lang } = pageContext
  const contactLink = lang === 'de' ? '/contact/' : `/${lang}/contact/`
  const contact = lang === 'de' ? 'Kontakt' : 'Contact'
  const impressumLink = lang === 'de' ? '/impressum/' : `/${lang}/impressum/`
  const impressum = 'Impressum'

  return (
    <footer className="footer mt-auto">
      <div className="container">
        <hr className="featurette-divider" />
        <p className="float-end">
          <Link to={contactLink}>{contact}</Link> &middot;{' '}
          <Link to={impressumLink}>{impressum}</Link>
        </p>
        <p>&copy; {new Date().getFullYear()} megalog AG</p>
      </div>
    </footer>
  )
}

export default Footer
