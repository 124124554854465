import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const HeaderTemplate = ({ data, pageContext }) => {
  const { id, pagePathShort, hasPath } = pageContext
  let lang = pageContext.lang || 'de'
  const { edges } = data.allMarkdownRemark

  const navLinkList = edges
    .filter((edge) => edge.node.frontmatter.templateKey !== 'dummy')
    .filter((edge) => edge.node.frontmatter.language === lang)
    .sort(
      (a, b) =>
        a.node.frontmatter.navigation.order -
        b.node.frontmatter.navigation.order,
    )
    .map((edge) => {
      const frontmatter = edge.node.frontmatter

      if (frontmatter.navigation.linkTitle === null) {
        return ''
      }

      const classes = `nav-link ${id === edge.node.id ? 'active' : ''}`

      return (
        <li className="nav-item" key={frontmatter.navigation.path}>
          <Link
            className={classes}
            to={
              lang === 'de'
                ? frontmatter.navigation.path
                : `/${lang}${frontmatter.navigation.path}`
            }
          >
            {frontmatter.navigation.linkTitle}
          </Link>
        </li>
      )
    })

  const langLinks = []

  for (const key in hasPath) {
    let path = '/'
    if (hasPath[key]) {
      path = pagePathShort
    }
    if (key !== 'de') {
      path = `/${key}${path}`
    }

    langLinks.push(
      <li key={`lang-${key}`}>
        <Link className="dropdown-item" to={path}>
          {key.toUpperCase()}
        </Link>
      </li>,
    )
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to={lang === 'de' ? '/' : `/${lang}/`}>
            <StaticImage
              src="../images/logo.png"
              layout="constrained"
              height={35}
              alt={''}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mb-2 mb-md-0 ms-auto">
              {navLinkList}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="languageDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {lang.toUpperCase()}
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                  aria-labelledby="languageDropdown"
                >
                  {langLinks}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default function Header({ pageContext }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                id
                frontmatter {
                  templateKey
                  language
                  navigation {
                    linkTitle
                    order
                    path
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <HeaderTemplate data={data} pageContext={pageContext} />
      )}
    />
  )
}
