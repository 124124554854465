import React from 'react'
import { Helmet } from 'react-helmet'

import Footer from './Footer'
import Header from './Header'

export default function Layout({ children, className, pageContext }) {
  return (
    <div
      style={{
        height: `100%`,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>megalog AG</title>
      </Helmet>

      <Header pageContext={pageContext} />

      <main className={className}>{children}</main>

      <Footer pageContext={pageContext} />
    </div>
  )
}
